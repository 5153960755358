<template>
  <form @submit.prevent="submitBody">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
    <CModalExtended
      :title="title"
      color="dark"
      size="xl"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      @update:show="resetForm"
    >
      <CRow class="align-items-center">
        <CCol sm="12" lg="4">
          <div class="form-group form-row" rol="group">
            <label class="col-form-label-sm col-sm-12 col-lg-4 required text-right mb-0">{{$t('label.vessel')}}</label>
            <div class="col-sm-12 col-lg-8">
              <v-select 
                id="v-select-small"
                style="font-size: 11px;"
                :placeholder="$t('label.select')"
                :class="!$v.form.Bl.VesselId.$dirty ? '' : ($v.form.Bl.VesselId.$error ? 'select-client--error' : 'select-client--correct')"
                :options="VesselOptions"
                :reduce="option => option.value"
                v-model="$v.form.Bl.VesselId.$model"
                :disabled="isEdit || fgInfo"
                @input="onInputVesselId"
              >
                <template #no-options="{ }">
                  {{$t('label.noResultsFound')}}
                </template>
              </v-select>
              <div class="text-invalid-feedback" v-if="$v.form.Bl.VesselId.$error">
                {{ errorMessage($v.form.Bl.VesselId) }}
              </div>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="4">
          <div class="form-group form-row align-items-center" rol="group">
            <label class="col-form-label-sm col-sm-12 col-lg-4 required text-right mb-0">{{$t('label.visitItineraty')}}</label>
            <div class="col-sm-12 col-lg-8">
              <v-select 
                id="v-select-small"
                style="font-size: 11px;"
                :placeholder="$t('label.select')"
                class="v-select-auto"
                :class="!$v.form.Bl.VisitIdOrItineraryId.$dirty ? '' : ($v.form.Bl.VisitIdOrItineraryId.$error ? 'select-client--error' : 'select-client--correct')"
                :options="VisitOrItineraryOptions"
                :reduce="option => option.value"
                v-model="$v.form.Bl.VisitIdOrItineraryId.$model"
                :disabled="!form.Bl.VesselId || isEdit || fgInfo"
                @input="onInputVisitIdOrItineraryId"
              >
                <template #no-options="{ }">
                  {{$t('label.noResultsFound')}}
                </template>
                <template #option="item">
                  <p v-if="item.value" class="m-0">{{ `${item.VesselName} - ${item.Voyage}` }}</p>
                  <p v-else class="m-0">{{ item.label }}</p>
                  <cite v-if="item.value">{{ `${item.LabelArrival ?? ''} ${formatDateTimeWithSlash(item.Arrival)} - ${item.LabelDeparture ?? ''} ${formatDateTimeWithSlash(item.Departure)}` }} </cite>
                </template>
              </v-select>
              <div class="text-invalid-feedback" v-if="$v.form.Bl.VisitIdOrItineraryId.$error">
                {{ errorMessage($v.form.Bl.VisitIdOrItineraryId) }}
              </div>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="4">
          <div class="form-group form-row" rol="group">
            <label class="col-form-label col-sm-12 col-lg-4 required text-right">{{$t('label.bl')}}</label>
            <div class="col-sm-12 col-lg-8">
              <v-select 
                id="v-select-small"
                :placeholder="$t('label.select')"
                style="font-size: 11px;"
                :class="!$v.form.Bl.BlCargoId.$dirty ? '' : ($v.form.Bl.BlCargoId.$error ? 'select-client--error' : 'select-client--correct')"
                :options="BlOption"
                :reduce="option => option.value"
                v-model="$v.form.Bl.BlCargoId.$model"
                :disabled="!form.Bl.VisitIdOrItineraryId || isEdit || fgInfo"
                @input="onChangeBlCargoId"
              >
                <template #no-options="{ }">
                  {{$t('label.noResultsFound')}}
                </template>
                <template #option="{ Json, label, value }">
                  <div v-if="value">
                    <b>{{ label }}</b><cite>{{ `(${Json?.TpBlName})` }}</cite>
                    <br/>
                    <cite v-if="value&&Json?.MasterBlNro">{{`${$t('label.Master')}: ${Json?.MasterBlNro}`}} </cite>
                  </div>
                  <div v-else>{{ label }}</div>
                </template>
              </v-select>
              <div class="text-invalid-feedback" v-if="$v.form.Bl.BlCargoId.$error">
                {{ errorMessage($v.form.Bl.BlCargoId) }}
              </div>
            </div>
          </div>
        </CCol>

        <CCol sm="12" lg="4">
          <CInput
            size="sm"
            v-uppercase
            :placeholder="$t('label.blType')"
            addLabelClasses="text-right"
            :label="$t('label.blType')"
            :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
            v-model="form.Bl.TpBlName"
            disabled
          />
        </CCol>
        <CCol sm="12" lg="4">
          <CInput
            size="sm"
            v-uppercase
            :placeholder="$t('label.blCargoMaster')"
            addLabelClasses="text-right"
            :label="$t('label.blCargoMaster')"
            :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
            v-model="form.Bl.MasterBlNro"
            disabled
          />
        </CCol>

        <CCol sm="12" lg="4">
          <CInput
            size="sm"
            v-uppercase
            :placeholder="$t('label.client')"
            addLabelClasses="text-right"
            :label="$t('label.client')"
            :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
            v-model="form.Bl.ClientName"
            disabled
          />
        </CCol>
        <CCol sm="12" lg="4">
          <CInput
            size="sm"
            v-uppercase
            class="align-items-center"
            :placeholder="$t('label.customBroker')"
            addLabelClasses="text-right"
            :label="$t('label.customBroker')"
            :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
            v-model="form.Bl.CustomBrokerName"
            disabled
          />
        </CCol>
        <CCol sm="12" lg="4">
          <CSelect
            v-uppercase
            size="sm"
            add-label-classes="required text-right"
            :label="$t('label.yard')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}" 
            :options="YardOptions"
            v-model="form.Bl.UbicationId"
            :is-valid="hasError($v.form.Bl.UbicationId)"
            :invalid-feedback="errorMessage($v.form.Bl.UbicationId)"
            :disabled="isEdit || fgInfo"
            @change="onChangeYardOriginId"
          />
        </CCol>

        <CCol sm="12" lg="4">
          <div class="form-group form-row d-flex aling-items-center mb-2">
            <label class="col-sm-12 col-lg-4 col-form-label-sm text-right required mb-0">{{`${$t('label.date')}`}}</label>
            <div class="input-group col-sm-12 col-lg-8">
              <vue-datepicker 
                type="datetime"
                header
                :lang="this.$i18n.locale"
                style="width: 100%;"
                :editable="false"
                :clearable="false"
                format="DD/MM/YYYY HH:mm"
                placeholder="DD/MM/YYYY HH:mm"
                time-title-format="DD/MM/YYYY HH:mm"
                v-model.trim="$v.form.Bl.TransactionDate.$model"
                :disabled-date="validateDateRange"
                :append-to-body="false"
                value-type="format"
                :show-second="false"
              >
                <template #input>
                    <CInput
                      v-uppercase
                      class="w-100 float-left"
                      v-model="$v.form.Bl.TransactionDate.$model"
                      placeholder="DD/MM/YYYY"
                      :is-valid="hasError($v.form.Bl.TransactionDate)"
                      @blur="$v.form.Bl.TransactionDate.$touch()"
                      :disabled="!form.Bl.BlCargoId || fgInfo"
                      size="sm"
                    >
                      <template #append-content>
                        <CIcon name="cil-calendar" />
                      </template>
                    </CInput>
                </template>
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="4">
          <CInput
            size="sm"
            v-uppercase
            class="align-items-center"
            :placeholder="$t('label.BolipuertoCertificateEntry')"
            addLabelClasses="text-right"
            :label="$t('label.BolipuertoCertificateEntry')"
            :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
            v-model="$v.form.Bl.ReceptionBpAct.$model"
            :is-valid="hasError($v.form.Bl.ReceptionBpAct)"
            :invalid-feedback="errorMessage($v.form.Bl.ReceptionBpAct)"
            :disabled="fgInfo"
          />
        </CCol>
        <CCol sm="12" lg="4">
          <CInput
            size="sm"
            v-uppercase
            :placeholder="$t('label.dua')"
            addLabelClasses="text-right"
            :label="$t('label.dua')"
            :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
            v-model="$v.form.Bl.Dua.$model"
            :is-valid="hasError($v.form.Bl.Dua)"
            :invalid-feedback="errorMessage($v.form.Bl.Dua)"
            :disabled="fgInfo"
          />
        </CCol>
        
        <CCol sm="12" lg="4">
          <CInput
            size="sm"
            v-uppercase
            :placeholder="$t('label.sidunea')"
            addLabelClasses="text-right"
            :label="$t('label.sidunea')"
            :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
            v-model="$v.form.Bl.Asycuda.$model"
            :is-valid="hasError($v.form.Bl.Asycuda)"
            :invalid-feedback="errorMessage($v.form.Bl.Asycuda)"
            :disabled="fgInfo"
          />
        </CCol>
        <CCol sm="12" lg="4">
          <CInput
            size="sm"
            v-uppercase
            :placeholder="$t('label.ReceivedBy')"
            addLabelClasses="text-right"
            :label="$t('label.ReceivedBy')"
            :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
            v-model="$v.form.Bl.ReceiverName.$model"
            :is-valid="hasError($v.form.Bl.ReceiverName)"
            :invalid-feedback="errorMessage($v.form.Bl.ReceiverName)"
            :disabled="fgInfo"
          />
        </CCol>
        <CCol sm="12" lg="4">
          <CInput
            size="sm"
            v-uppercase
            :placeholder="$t('label.ReceivedId')"
            addLabelClasses="text-right"
            :label="$t('label.ReceivedId')"
            :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
            v-model="$v.form.Bl.ReceiverCardId.$model"
            :is-valid="hasError($v.form.Bl.ReceiverCardId)"
            :invalid-feedback="errorMessage($v.form.Bl.ReceiverCardId)"
            :disabled="fgInfo"
          />
        </CCol>
       
        <CTabs :active-tab="tabIndex" @update:activeTab="handleTab" class="col-sm-12">
          <CTab :title="$t('label.generalData')">
            <div class="border border-top-0 rounded-bottom">
              <GeneralData
                :form="form"
                :vForm="$v.form"
                :fgInfo="fgInfo"
              />
            </div>
           
          </CTab>
          <CTab :title="$t('label.DescriptionOfGoods')">
            <div class="border border-top-0 rounded-bottom">
              <DescriptionOfGoods
                :form="form"
                :vForm="$v.form"
                :fgInfo="fgInfo"
              />
            </div>
          
          </CTab>
          <CTab :title="$t('label.location')">
            <div class="border border-top-0 rounded-bottom">
              <Location
                :form="form"
              />
            </div>
          </CTab>
          <CTab v-if="isEdit" :title="$t('label.status')">
            <div class="border border-top-0 rounded-bottom">
              <Status
                :form="form"
                :VForm="$v.form"
                :dateRange="dateRange"
                :tab="showModal && tabIndex == 3"
                :showModal="showModal"
                :FgUpdate="FgUpdate"
                :fgInfo="ValidateInative"
                @Loading="Loading=$event"
                @UpdateCertificateOfEntryItem="$emit('UpdateCertificateOfEntryItem', $event)"
                @Update="$emit('Update-list')"
              />
            </div>
          </CTab>
        </CTabs>
      </CRow>
      <template #footer>
        <CButton
          v-if="!fgInfo"
          square
          color="add"
          class="d-flex align-items-center"
          @click.stop="submit(!isEdit)"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="resetForm"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </form>
</template>
<script>
import { mapState } from 'vuex';
import ModalMixin from '@/_mixins/modal';
import CertificateOfEntryValidations from '@/_validations/certificate-of-entry/certificateOfEntryValidations';
import UpperCase from '@/_validations/uppercase-directive';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import { Money } from "v-money";
import GeneralData from './tabs/generalData';
import DescriptionOfGoods from './tabs/descriptionOfGoods';
import Location from './tabs/location';
import Status from './tabs/status';
import { DateFormater, NumberFormater, removeTags } from '@/_helpers/funciones';
import moment from 'moment';

function data() {
  return {
    Loading: false,
    isSubmit: false,
    showModal: false,
    tabIndex: 0,
    form: {
      Bl:{
        YardDocumentId: '',
        VesselId: '',
        VisitIdOrItineraryId: '',
        BlCargoId: '',
        TpBlName: '',
        MasterBlNro: 'N/A',
        ClientName: '',
        CustomBrokerName: '',
        UbicationId: '',
        TransactionDate: '',
        Dua: '',
        ReceiverName: '',
        ReceiverCardId: '',
        ReceptionBpAct: '',
        Asycuda: '',
      },
      GeneralData: {
        PortActivityName: '',
        Quantity: 0,
        Weight: 0,
        Volumen: 0,
        ReceptionList: [],
      },
      DescriptionOfGoods: {
        DescriptionOfGoods: '',
      },
      Location: {
        UbicationJson: []
      },
      Status: {
        YardDocumentStatusHistId: '',
        DocumentYardStatusId: '',
        TransactionDate: '',
        Observation: '',
      }
    },
    previousDate: '',
    laterDate: '',
    previousStatusDate: '',
    VesselList: [],
    VisitOrItineraryList: [],
    BlList: [],
    UbicationList: [],
  };
}

//-----------------------   Method    ------------------------
async function getService() {
  let peticiones = [
    this.$http.ejecutar("GET", "VesselServiceItinerary-by-CompanyBranchId", { CompanyBranchId: this.branch.CompanyBranchId }),
  ];
   await Promise.all(peticiones)
    .then(async(responses) => {
      this.VesselList = responses[0]?.data?.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

async function getVisitOrItineraryList(loading = false) {
  this.Loading = true;
  await this.$http.get("YardBlCargoVisitItinerary-by-CompanyBranchId", { VesselId: this.form.Bl.VesselId, CompanyBranchId: this.branch.CompanyBranchId })
  .then(response => {
    this.VisitOrItineraryList = response.data.data ?? [];
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
  .finally(() => {
    this.Loading = loading;
  });
}

async function getBlCargoList(loading = false) {
  this.Loading = true;
  let Vessel = this.VisitOrItineraryOptions.find(e => e.value == this.form.Bl.VisitIdOrItineraryId) ?? {};
  let method = this.isEdit ? 'BlCargo-by-Id' : 'BlCargoReceived-list';
  let parameter = this.isEdit ? { BlCargoId: this.CertificateOfEntryItem.BlcargoId } : 
    { BlCargoJson: { BlCargoJson: [{VisitId: Vessel?.VisitId ?? '', ItineraryId: Vessel?.ItineraryId ?? '', SearchKey: ''}] } }
  await this.$http.get(method, parameter)
  .then(response => {
    this.BlList = response?.data?.data ?? [];
  }).catch( err => {
    this.$notify({
    group: 'container',
    title: '¡Error!',
    text: err,
    type: "error"
    });
  })
  .finally(() => {
    this.Loading = loading;
  });
}

async function getYardWCargoReceptionDetailByBlCargoYardId(loading = false) {
  this.Loading = true;
  await this.$http.get("YardWCargoReceptionDetail-by-BlCargoYardId", { BlCargoId: this.form.Bl.BlCargoId, UbicationId: this.form.Bl.UbicationId })
  .then(response => {
    let data = response?.data?.data?.[0] ?? {};
    this.form.GeneralData.ReceptionList = data?.DetailJson ?? [];
    this.form.GeneralData.Quantity = data?.SummaryJson?.[0]?.QuantityReceived ?? 0;
    this.form.GeneralData.Weight = data?.SummaryJson?.[0]?.WeigthReception ?? 0;
    this.form.GeneralData.Volumen = data?.SummaryJson?.[0]?.VolumenReception ?? 0;
    this.previousStatusDate = data?.SummaryJson?.[0]?.MaxDate ? moment(data.SummaryJson[0].MaxDate ,'YYYY-MM-DD HH:mm').toDate() : '';
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.Loading = loading;
  });
}

async function getYardDocumentBlCargoUbicationList(loading = false){
  this.Loading = true;
  let YardDocumentJson = {
    YardDocumentJson: [{ 
      BlCargoId: this.form.Bl.BlCargoId, 
      UserId: this.user.UserId,
      RoleId: this.branch.RoleId,
    }]
  }
  await this.$http.get("YardDocumentBlCargoUbication-list", { YardDocumentJson: YardDocumentJson })
  .then(response => {
    this.UbicationList = response.data.data ?? [];
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.Loading = loading;
  });
}

function submit(isInsert = false) {
  try {
    this.Loading = true;
    this.isSubmit = true;
    this.$v.form.Bl.$touch();
    this.$v.form.GeneralData.$touch();
    this.$v.form.DescriptionOfGoods.$touch();
    
    if (this.$v.form.Bl.$error || this.$v.form.GeneralData.$error || this.$v.form.DescriptionOfGoods.$error) {
      let Error = this.TransactionDateError(this.form.Bl.TransactionDate)
      if (Error) {
        throw Error;
      }
      throw this.$t('label.errorsPleaseCheck');
    }

    if (this.form.Status.DocumentYardStatusId || this.form.Status.TransactionDate || this.form.Status.Observation) {
      throw this.$t('validation.StatusTabCleared');
    }

    let YardDocumentJson = [{
      YardDocumentId: this.form.Bl.YardDocumentId ?? '',
      UbicationId: this.form.Bl.UbicationId,
      BlcargoId: this.form.Bl.BlCargoId,
      TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.form.Bl.TransactionDate),
      WeigthReception: this.form.GeneralData.Weight,
      VolumenReception: this.form.GeneralData.Volumen,
      ReceptionBpAct: this.form.Bl.ReceptionBpAct,
      Dua: this.form.Bl.Dua,
      Asycuda: this.form.Bl.Asycuda,
      ReceiverName: this.form.Bl.ReceiverName,
      ReceiverCardId: this.form.Bl.ReceiverCardId,
      DescriptionOfGoods: this.form.DescriptionOfGoods.DescriptionOfGoods ?? '',
    }]

    let metodo = this.isEdit ? 'PUT' : 'POST';
    let ruta = this.isEdit ? 'YardDocumentBlCargo-update' : 'YardDocumentBlCargo-insert';

    this.$http.ejecutar(metodo, ruta, YardDocumentJson, { root: 'YardDocumentJson' })
      .then(async(response) => {
        if (response && response.status === (200 || 201)) {
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data[0].Response,
            type: "success"
          });
          if (isInsert) {
            this.form.Bl.YardDocumentId = response.data.data[0].YardDocumentId;
            await this.$emit('isEdit', true);
            await this.$emit('UpdateCertificateOfEntryItem', response.data.data[0]);
          }else{
            await this.resetForm();
          }
          await this.$emit('Update-list');
          if (isInsert) {
            this.tabIndex = 3;
          }else{
            this.Loading = false;
          }
          this.isSubmit = false;
        }
      }).catch((e) => {
        this.Loading = false;
        this.isSubmit = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.Loading = false;
    this.isSubmit = false;
    this.notifyError({text: e});
  }
}

function onInputVesselId() {
  this.form.Bl.VisitIdOrItineraryId = '';
  this.VisitOrItineraryList = [];
  this.form.Bl.BlCargoId = '';
  this.form.BlClientName = '';
  this.form.BlCustomBrokerName = '';
  this.BlList = [];
  if (this.form.Bl.VesselId) {
    this.getVisitOrItineraryList();
  }
}

function onInputVisitIdOrItineraryId() {
  this.form.Bl.BlCargoId = '';
  this.form.BlClientName = '';
  this.form.BlCustomBrokerName = '';
  this.BlList = [];
  if (this.form.Bl.VisitIdOrItineraryId) {
    this.getBlCargoList();
  }
}

async function onChangeBlCargoId() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let BLData = {};
  this.form.GeneralData.ReceptionList = [];
  this.form.GeneralData.Quantity = 0;
  this.form.GeneralData.Weight = 0;
  this.form.GeneralData.Volumen = 0;
  if (this.form.Bl.BlCargoId) {
    BLData = this.BlList.find(e => e.BlCargoId == this.form.Bl.BlCargoId);
    if (this.form.Bl.UbicationId) {
      await this.getYardWCargoReceptionDetailByBlCargoYardId();
    }else{
      await this.getYardDocumentBlCargoUbicationList()
    }
  }
  this.form.Bl.TransactionDate = '';
  this.form.Bl.TpBlName = BLData?.TpBlName ?? ''
  this.form.Bl.MasterBlNro = BLData?.MasterBlNro ?? 'N/A'
  this.form.Bl.ClientName = BLData?.ClientName ?? '';
  this.form.Bl.CustomBrokerName = BLData?.CustomBrokerName ?? '';
  this.form.GeneralData.PortActivityName = BLData?.[`PortActivityName${_lang}`] ?? '';
  this.form.DescriptionOfGoods.DescriptionOfGoods = BLData?.DescriptionOfGoods ? removeTags(BLData?.DescriptionOfGoods) : '';
  this.form.Location.UbicationJson = BLData?.UbicationJson ? BLData?.UbicationJson.map((e, index) => { return {...e, Nro: index+1, FgCollapse: false} }) : [];
  
  this.previousDate = BLData?.FirstEventDate ? moment(BLData.FirstEventDate ,'YYYY-MM-DD HH:mm').toDate() : '';
  this.laterDate = new Date();
}

function onChangeYardOriginId() {
  this.form.GeneralData.ReceptionList = [];
  this.form.GeneralData.Quantity = 0;
  this.form.GeneralData.Weight = 0;
  this.form.GeneralData.Volumen = 0;
  if (this.form.Bl.UbicationId && this.form.Bl.BlCargoId) {
    this.getYardWCargoReceptionDetailByBlCargoYardId();
  }
}

async function getData(item) {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  this.form.Bl.YardDocumentId = item.YardDocumentId;
  this.form.Bl.VesselId = item.VesselId;
  this.form.Bl.VisitIdOrItineraryId = item.VisitId ?? item.ItineraryId;
  
  this.form.Bl.Dua = item.Dua;
  this.form.Bl.ReceiverName = item.ReceiverName;
  this.form.Bl.ReceiverCardId = item.ReceiverCardId;
  this.form.Bl.ReceptionBpAct = item.ReceptionBpAct;
  this.form.Bl.Asycuda = item.Asycuda;

  await this.getVisitOrItineraryList(true);
  await this.getBlCargoList(true);

  this.form.Bl.BlCargoId = item.BlcargoId;

  await this.getYardDocumentBlCargoUbicationList(true)

  this.form.Bl.UbicationId = item.UbicationId;

  await this.getYardWCargoReceptionDetailByBlCargoYardId(true)

  let BLData = this.BlList.find(e => e.BlCargoId == this.form.Bl.BlCargoId);
  this.form.Bl.TpBlName = BLData?.TpBlName ?? ''
  this.form.Bl.MasterBlNro = BLData?.MasterBlNro ?? 'N/A'
  this.form.Bl.ClientName = BLData?.ClientName ?? '';
  this.form.Bl.CustomBrokerName = BLData?.CustomBrokerName ?? '';
  this.previousDate = BLData?.FirstEventDate ? moment(BLData.FirstEventDate ,'YYYY-MM-DD HH:mm').toDate() : '';
  this.laterDate = new Date();
  this.form.GeneralData.PortActivityName = BLData?.[`PortActivityName${_lang}`] ?? '';
  this.form.Bl.TransactionDate = item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '';
  this.form.DescriptionOfGoods.DescriptionOfGoods = BLData?.DescriptionOfGoods ? removeTags(BLData?.DescriptionOfGoods) : '';
  this.form.Location.UbicationJson = BLData?.UbicationJson ? BLData?.UbicationJson.map((e, index) => { return {...e, Nro: index+1, FgCollapse: false} }) : [];
  this.$v.$touch();
  this.$v.form.Status.$reset();
}

function handleTab(tab) {
  this.tabIndex = tab;
}

function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A';
}

function validateDateRange(date) {
  return date < DateFormater.formatDateRange(this.dateRange.Bl.previousDate) || date > DateFormater.formatDateRange(this.dateRange.Bl.laterDate);
}

function ValidatePreviousDate(TransactionDate, previousStatusDate) {
  if (TransactionDate && previousStatusDate) {
    return DateFormater.formatDateTimeWithoutSlash(TransactionDate) < DateFormater.formatTimeZoneToDateTimewithDash(previousStatusDate) ? previousStatusDate : DateFormater.formatDateRangeWithSlash(TransactionDate)
  }else{
    return undefined
  }
}

function TransactionDateError(date) {
  if (date) {
    if (DateFormater.formatDateTimeWithoutSlash(date) < this.dateRange.Bl.previousDate) {
      return this.$t('validation.minDateCertificate');
    }else if (DateFormater.formatDateTimeWithoutSlash(date) > this.dateRange.Bl.laterDate) {
      return this.$t('label.ErrorCurrentDatedPleaseCheck');
    }
  }else {
    return false
  }
}

async function resetForm() {
  this.isSubmit = false;
  this.tabIndex = 0;
  this.form = {
    Bl:{
      YardDocumentId: '',
      VesselId: '',
      VisitIdOrItineraryId: '',
      BlCargoId: '',
      TpBlName: '',
      MasterBlNro: 'N/A',
      ClientName: '',
      CustomBrokerName: '',
      UbicationId: '',
      TransactionDate: '',
      Dua: '',
      ReceiverName: '',
      ReceiverCardId: '',
      ReceptionBpAct: '',
      Asycuda: '',
    },
    GeneralData: {
      PortActivityName: '',
      Quantity: 0,
      Weight: 0,
      Volumen: 0,
      ReceptionList: [],
    },
    DescriptionOfGoods: {
      DescriptionOfGoods: '',
    },
    Location: {
      UbicationJson: []
    },
    Status: {
      YardDocumentStatusHistId: '',
      DocumentYardStatusId: '',
      TransactionDate: '',
      Observation: '',
    }
  }
  this.VesselList = [];
  this.VisitOrItineraryList = [];
  this.BlList = [];
  this.VesselList = [];
  this.UbicationList = [];
  this.previousDate = '';
  this.laterDate = '';
  //this.$v.$reset();
  await this.$emit('close');
}

//-----------------------  Computed  ------------------------
function title() {
  if (this.isEdit) {
    return `${this.$t('label.edit')} ${this.$t('label.CertificateEntry')}: ${this.CertificateOfEntryItem?.DocumentCode ?? ''}`;
  } else {
    return `${this.$t('label.nueva')} ${this.$t('label.CertificateEntry')}`;
  }
}

function VesselOptions(){
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.VesselList.map(function(e){
    chart.push({
      ...e,
      value: e.VesselId, 
      label: e.VesselName,
    })
  })
  return chart;
}

function VisitOrItineraryOptions(){
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.VisitOrItineraryList.map(function(e){
    chart.push({
      ...e,
      value: e.VisitId ?? e.ItineraryId, 
      label: `${e.Voyage} - ${e.LabelArrival ?? ''} ${formatDateTimeWithSlash(e.Arrival)}`,
    })
  })
  return chart;
}

function BlOption() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }];
  this.BlList.map(e => {
    chart.push({
      value: e.BlCargoId,
      label: e.BlNro,
      Json: {...e}
    });
  });
  return chart;
}

function YardOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }] 
  this.UbicationList?.map((e) => {
    chart.push({
      value: e.UbicationId,
      label: e.UbicationName,
    })
  })
  return chart;
}

function dateRange() {
  return {
    Bl: {
      previousDate: DateFormater.formatTimeZoneToDateTimewithDash(this.previousDate),
      laterDate: DateFormater.formatTimeZoneToDateTimewithDash(this.laterDate),
    },
    Status: {
      fgStatusDate: DateFormater.formatDateTimeWithoutSlash(this.form.Bl.TransactionDate) < DateFormater.formatTimeZoneToDateTimewithDash(this.previousStatusDate),
      previousDate: this.form.Bl.TransactionDate && this.previousStatusDate ? DateFormater.formatTimeZoneToDateTimewithDash(ValidatePreviousDate(this.form.Bl.TransactionDate, this.previousStatusDate)) : undefined,
      laterDate: DateFormater.formatTimeZoneToDateTimewithDash(new Date()),
    }
  }
}

function TransactionDate() {
  return this.form.Bl.TransactionDate
}

function fgInfo() {
  return this.CertificateOfEntryItem?.DocumentYardStatus == process.env.VUE_APP_DOCUMENT_YARD_STATUS_ID_CERTIFICATE || this.CertificateOfEntryItem?.DocumentYardStatus == process.env.VUE_APP_DOCUMENT_YARD_STATUS_ID_INACTIVE;
}

function FgUpdate() {
  return this.CertificateOfEntryItem?.DocumentYardStatus == process.env.VUE_APP_DOCUMENT_YARD_STATUS_ID_PENDING_CERTIFICATION ||
    this.CertificateOfEntryItem?.DocumentYardStatus == process.env.VUE_APP_DOCUMENT_YARD_STATUS_ID_DECERTIFIED;
}

function ValidateInative() {
  return this.CertificateOfEntryItem?.DocumentYardStatus == process.env.VUE_APP_DOCUMENT_YARD_STATUS_ID_INACTIVE
}

export default {
  name: 'certificate-of-entry-modal',
  components: {
    CustomTabs,
    CustomTab,
    Money,
    GeneralData,
    DescriptionOfGoods,
    Location,
    Status,
  },
  props: { modal: Boolean, isEdit: Boolean, CertificateOfEntryItem: Object },
  data,
  mixins: [
    ModalMixin,
  ],
  validations() {
    return CertificateOfEntryValidations(this.dateRange)
  },
  directives: UpperCase,
  methods: {
    getService,
    getVisitOrItineraryList,
    getBlCargoList,
    getYardWCargoReceptionDetailByBlCargoYardId,
    getYardDocumentBlCargoUbicationList,
    submit,
    onInputVesselId,
    onInputVisitIdOrItineraryId,
    onChangeBlCargoId,
    onChangeYardOriginId,
    getData,
    handleTab,
    formatDateTimeWithSlash,
    validateDateRange,
    ValidatePreviousDate,
    TransactionDateError,
    resetForm,
  },
  computed: {
    title,
    VesselOptions,
    VisitOrItineraryOptions,
    BlOption,
    YardOptions,
    dateRange,
    TransactionDate,
    fgInfo,
    FgUpdate,
    ValidateInative,
    ...mapState({
      branch: state => state.auth.branch,
      user: state => state.auth.user,
    })
  },
  watch: {
    modal: async function (NewVal) {
      this.$v.$reset();
      this.showModal = NewVal;
      if (NewVal) {
        this.Loading = true;
        await this.getService();
        if (this.isEdit) {
          await this.getData(this.CertificateOfEntryItem);
        }
        this.Loading = false;
      }else {
        this.resetForm();
      }
    },
    TransactionDate: function (NewVal) {
      try {
        let Error = this.TransactionDateError(NewVal)
        if (Error) {
          throw Error;
        }
      } catch (error) {
        this.notifyError({text: error});
      }
    }
  },
};
</script>
